import { render, staticRenderFns } from "./Setting.vue?vue&type=template&id=7ac38260&scoped=true&"
import script from "./Setting.vue?vue&type=script&lang=js&"
export * from "./Setting.vue?vue&type=script&lang=js&"
import style0 from "./Setting.vue?vue&type=style&index=0&id=7ac38260&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ac38260",
  null
  
)

export default component.exports